export const VIDEO_PREFIX = `https://56utils.s3.ca-central-1.amazonaws.com/how-to-play/Skyvideos/`;

export const data = {
  title: "LEGENDS OF SKY",
  img: { url: "/images/lore/cover.jpg" },
  text: `
	<p>
	Across the ages, the Prisms have chosen their champions—the Skyweavers—to weave the energies of the Sky Source, protect Sky’s people, and guide them towards a better future.</p>
<p>But all is not well in Sky. A mysterious energy storm tears across its foundation, sowing chaos and destruction. The Hexbound encroach on the lands of the living, and rumors spread of the Genesis Prism, an artifact capable of reshaping all of Sky to its bearer’s will. Its power could usher an era of peace or plunge everything into eternal strife.</p>
	<p>The future of Sky hangs in the balance. Do you have what it takes to join the fight?</p>
	`,
  heroes: {
    title: "Heroes",
    text: "Each playable Skyweaver Hero represents a Prism or combination of Prisms.",
    content: [
      {
        name: "ADA",
        alternateName: "The Guardian",
        text: "A stalwart defender of justice, Ada strives to protect those in Sky who cannot protect themselves.",
        quote: `Someone needs to save the day.`,
        image: "/lore/ADA.jpg",
        imageThumbnail: "/images/how-to-play/heroes/ADA-thumb.png",
        howToPlayText:
          "Call upon powerful units and cast devastating spells to overpower and crush your enemies and bolster your allies. All will tremble before your unstoppable might.",
        heroImg: `/images/heroes/hero-giaco-01@6x.png`,
        prism: "strength",
      },
      {
        name: "LOTUS",
        alternateName: "The Sage",
        text: "A great teacher, and master of the grand Library of Alcazar, Lotus works to maintain the delicate balance of Sky.",
        quote: `Knowledge is our greatest legacy.`,
        howToPlayText:
          "Your deep understanding of the sky offers you advantages beyond measure. Wield ancient power and your own spirit to humble your foes.",
        heroImg: `/images/heroes/hero-brian-01@6x.png`,
        image: "/lore/LOTUS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/LOTUS-thumb.png",
        prism: "wisdom",
      },
      {
        name: "SAMYA",
        alternateName: "The Challenger",
        text: "A superstar speedster, Samya races across Sky, seeking new experiences and challenges to overcome.",
        quote: `I’ll reach the horizon, and what lies beyond.`,
        image: "/lore/SAMYA.jpg",
        howToPlayText:
          "Move and strike with the fury of lightning, outpacing and overwhelming all challengers in a flurry of speed. Your foes won’t even know what hit them",
        heroImg: `/images/heroes/hero-giaco-02@6x.png`,
        imageThumbnail: "/images/how-to-play/heroes/SAMYA-thumb.png",
        prism: "agility",
      },
      {
        name: "BOURAN",
        alternateName: "The Shadow",
        text: "A mysterious wanderer from the Undercroft, their goals are a mystery, though they claim to serve the will of ‘The Cycle’.",
        quote: `...`,
        howToPlayText:
          "Harness the powers of both life and death to vanquish your foes and keep your allies coming back from beyond the grave. Who can oppose death itself?",
        heroImg: `/images/heroes/hero-brian-02@6x.png`,
        image: "/lore/BOURAN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/BOURAN-thumb.png",
        prism: "heart",
      },
      {
        name: "ARI",
        alternateName: "The Hacker",
        text: "A prodigy, Ari aspires to improve the lives of all Sky’s peoples through his genius.",
        quote: `This world is just one more puzzle to solve.`,
        howToPlayText:
          "Orchestrate elaborate strategies to out-think and dominate all who oppose you, and leave them helpless to retaliate. Your mind is the greatest weapon of all",
        heroImg: `/images/heroes/hero-xavi-01@6x.png`,
        image: "/lore/ARI.jpg",
        imageThumbnail: "/images/how-to-play/heroes/ARI-thumb.png",
        prism: "intellect",
      },
      {
        name: "TITUS",
        alternateName: "The Hermit",
        text: "A gentle soul at one with nature, Titus dedicates his life to protecting Sky’s wilderness and all creatures that call it home.",
        howToPlayText:
          "Strength of body and of soul walk hand in hand, granting you unshakable resolve. True victory belongs not to those who destroy, but those who endure.",
        quote: `Keep the beat, follow the melody.`,
        image: "/lore/TITUS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/TITUS-thumb.png",
        heroImg: `/images/heroes/hero-giaco-12@6x.png`,
        prism: "strength-wisdom",
      },
      {
        name: "FOX",
        alternateName: "The Vagabond",
        text: "A renegade following his own path, Fox prizes freedom above all else, determined to never be chained by anything or anyone.",
        quote: `Don't act like you're not impressed, sweetheart!`,
        howToPlayText:
          "Strike with overwhelming might and speed in a one-two punch that will send opponents sprawling. Overrun and destroy all who would challenge you.",
        image: "/lore/FOX.jpg",
        imageThumbnail: "/images/how-to-play/heroes/FOX-thumb.png",
        heroImg: `/images/heroes/hero-giaco-03@6x.png`,
        prism: "strength-agility",
      },
      {
        name: "HORIK",
        alternateName: "The Exile",
        text: "Formerly a respected Hero and leader, a tragedy drove Horik to become a lone wolf, determined to recover that which he lost.",
        howToPlayText: `Wield your mastery over life and death to bring your mightiest units back again and again. Your allies will shrug off death even as they destroy their foes.`,
        quote: `I won’t bow to fate. It will bow to me.`,
        heroImg: `/images/heroes/hero-giaco-04@6x.png`,
        image: "/lore/HORIK.jpg",
        imageThumbnail: "/images/how-to-play/heroes/HORIK-thumb.png",
        prism: "strength-heart",
      },
      {
        name: "IRIS",
        alternateName: "The Loreseeker",
        text: "A student of the Cygnan monks, Iris is on a pilgrimage across Sky, gathering the knowledge of the land and its peoples.",
        quote: `To know the world is to know oneself.`,
        howToPlayText: `Channel your inner calm and outer fury to become the flowing stream and the raging storm. Overtake and sweep away all that stand in your path to victory.`,
        heroImg: `/images/heroes/hero-giaco-11@6x.png`,
        image: "/lore/IRIS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/IRIS-thumb.png",
        prism: "agility-wisdom",
      },
      {
        name: "ZOEY",
        alternateName: "The Maverick",
        text: "A rebel against tyranny, Zoey fights to liberate others from their oppressors by any means necessary.",
        quote: `Life is fleeting, don’t waste a moment of it!`,
        image: "/lore/ZOEY.jpg",
        howToPlayText: `Hit fast and hard with units that fight on beyond death, and spells that drain the life from your adversaries. Life is fleeting, that of your foes most of all. `,
        heroImg: `/images/heroes/hero-giaco-06@6x.png`,
        imageThumbnail: "/images/how-to-play/heroes/ZOEY-thumb.png",
        prism: "heart-agility",
      },
      {
        name: "AXEL",
        alternateName: "The Mercenary",
        text: "Once a source of hope for others, Axel was driven to despair by his visions of the future, turning to life as a mercenary.",
        howToPlayText: `Deep understanding and respect for the cycle of life grants you power few can imagine. Where others would fear death, you walk alongside it as an equal.`,
        quote: `The only certainty in life is its ending.`,
        image: "/lore/AXEL.jpg",
        heroImg: `/images/heroes/hero-giaco-07@6x.png`,
        imageThumbnail: "/images/how-to-play/heroes/AXEL-thumb.png",
        prism: "heart-wisdom",
      },
      {
        name: "SITTI",
        alternateName: "The Warlock",
        text: "A delver of forbidden sciences, she conducts grim experiments into the nature of life and death, working toward a grand vision only she can see.",
        quote: `True progress requires sacrifice.`,
        howToPlayText: `Strategic prowess and the ability to manipulate life and death offers you endless tactical possibilities. Outsmart both your opponents, and death itself.`,
        heroImg: `/images/heroes/hero-giaco-09@6x.png`,
        image: "/lore/SITTI.jpg",

        imageThumbnail: "/images/how-to-play/heroes/SITTI-thumb.png",
        prism: "heart-intellect",
      },
      {
        name: "BANJO",
        alternateName: "The Cubeman",
        text: "An eccentric, mischievous and cheerful figure, he provides insightful advice and good cheer to those who meet him.",
        howToPlayText: `Wield tactical prowess alongside mental and spiritual fortitude to outsmart and outlast your foes. True power lies not in simply knowing, but understanding.`,
        quote: `That's a quote!`,
        image: "/lore/BANJO.jpg",
        heroImg: `/images/heroes/hero-giaco-05@6x.png`,
        imageThumbnail: "/images/how-to-play/heroes/BANJO-thumb.png",
        prism: "intellect-wisdom",
      },
      {
        name: "MIRA",
        alternateName: "The Mechanist",
        text: "A genius mechanic and roboticist, she values planning, stability, and always works to protect others from harm.",
        quote: `Nothing is impossible! All good things start with a vision - and a plan!`,
        howToPlayText: `Combine both physical might and mental fortitude to triumph over foes in both strength and strategy. Why settle for brain or brawn when you can wield both?`,
        image: "/lore/MIRA.jpg",
        imageThumbnail: "/images/how-to-play/heroes/MIRA-thumb.png",
        heroImg: `/images/heroes/hero-giaco-08@6x.png`,
        prism: "strength-intellect",
      },
      {
        name: "MAI",
        alternateName: "The Tinkerer",
        text: "An unparalleled ideator & tinkerer, she values discovery, impulsiveness, and loves to inspire creativity in others.",
        quote: `I don’t make mistakes, things just break around me`,
        howToPlayText: `Outpace and outwit your foes at every turn with clever strategies and swift units and spells that will leave them spinning. Few can hope to match the speed of thought.`,
        image: "/lore/MAI.jpg",
        imageThumbnail: "/images/how-to-play/heroes/MAI-thumb.png",
        heroImg: `/images/heroes/hero-giaco-10@6x.png`,
        prism: "agility-intellect",
      },
    ],
  },
  prisms: {
    title: "Prisms",
    text: "Prisms are the central forces that give shape to the world of Sky and all life within it. <br/>Prisms are also the power source that Skyweavers draw their powers from. ",
    content: [
      {
        name: "strength",
        text: "The Strength Prism embodies both physical might and unbreakable spirit. At their best, Strength Skyweavers are valiant protectors. At their worst, they may become bullies or overlords.",
        image: "/lore/STR.png",
        thumbnail: `/images/prisms/STR.png`,
        video: { url: `${VIDEO_PREFIX}lifesteal.mp4` },
      },
      {
        name: "agility",
        text: "The Agility Prism embodies freedom and individuality. At their best, Agility skyweavers defend the freedom of all. At their worst, they can become hedonistic and self-centered.",
        image: "/lore/AGI.png",
        thumbnail: `/images/prisms/AGY.png`,
        video: { url: `${VIDEO_PREFIX}lifesteal.mp4` },
      },
      {
        name: "wisdom",
        text: "The Wisdom prism embodies understanding and being at one with all of Sky. At their best, Wisdom Skyweavers are great mentors and teachers. At their worst, they can become cold and aloof, withdrawing from the world.",
        image: "/lore/WIS.png",
        thumbnail: `/images/prisms/WIS.png`,
        video: { url: `${VIDEO_PREFIX}lifesteal.mp4` },
      },
      {
        name: "heart",
        text: "The Heart Prism embodies ‘The Cycle’ of Life, Death and Rebirth. At their best, Heart Skyweavers seek to work with the Cycle to heal and support the living. At their worst, they can seek to use the cycle for their own ends.",
        image: "/lore/HRT.png",
        thumbnail: `/images/prisms/HRT.png`,
        video: { url: `${VIDEO_PREFIX}lifesteal.mp4` },
      },
      {
        name: "intellect",
        text: "The Intellect Prism embodies the Pursuit of Knowledge and Improvement. At their best, Intellect Skyweavers strive to improve the lives of all through their genius. At their worst, they can become blinded by ambitions.",
        image: "/lore/INT.png",
        thumbnail: `/images/prisms/INT.png`,
        video: { url: `${VIDEO_PREFIX}lifesteal.mp4` },
      },
    ],
  },
  factions: {
    title: "Tribes and Factions",
    text: "Sky is home to countless fantastic tribes, cultures, and factions of creatures across its eight elements.",
    content: [
      {
        name: "CYGNANS",
        text: "Swan monks who hone their bodies and minds through rigorous martial training and study of the history of Sky.",
        image: "/lore/CYGNANS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/CYGNANS-thumb.png",
        faction: "air",
      },
      {
        name: "PRIMALANS",
        text: "A nomadic ancient race of half-bird people, who claim to be the descendants of the Qui.",
        image: "/lore/PRIMALANS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/PRIMALANS-thumb.png",
        faction: "air",
      },
      {
        name: "SERVITORS",
        text: "Ancient beings from the age of Qui, driven by inscrutable and mysterious motives.",
        image: "/lore/SERVITORS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/SERVITORS-thumb.png",
        faction: "air",
      },
      {
        name: "RAPTORS",
        text: "A powerful and fiercely independent bird-people that populate the outer regions of Sky.",
        image: "/lore/RAPTORS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/RAPTORS-thumb.png",
        faction: "air",
      },
      {
        name: "WU-KIN",
        text: "A wise race of primates that live in and protect the ancient ruins of Sky, and their secrets, from outsiders.",
        image: "/lore/WU-KIN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/WU-KIN-thumb.png",
        faction: "air",
      },
      {
        name: "TORTUGAN",
        text: "A wise, intellectual, and long-lived race of turtle people who have a technologically advanced society.",
        image: "/lore/TORTUGAN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/TORTUGAN-thumb.png",
        faction: "water",
      },
      {
        name: "SIRENS",
        text: "A wild and predatory subspecies of carnivorous Merfolk with tight communal bonds.",
        image: "/lore/SIRENS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/SIREN-thumb.png",
        faction: "water",
      },
      // {
      // 	name: "MERFOLK",
      // 	text: "Mysterious guardians of the deep sea and its secrets, they prefer to keep to themselves, far below the sea.",
      // 	image: "/lore/SIRENS.jpg",
      // 	imageThumbnail: "/images/how-to-play/heroes/SIREN-thumb.png",
      // 	faction: "water"
      // },
      {
        name: "WRAITHS",
        text: "An ageless, ancient, cunning race of spirits that hunger for the lifeforce of the living.",
        image: "/lore/WRAITHS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/WRAITHS-thumb.png",
        faction: "dark",
      },
      {
        name: "EXO",
        text: "A feared and vengeful species of insect people, banished to the Undercroft in ancient times.",
        image: "/lore/THE-EXO.jpg",
        imageThumbnail: "/images/how-to-play/heroes/THE-EXO-thumb.png",
        faction: "dark",
      },
      {
        name: "Revenants",
        text: "An ancient tribe of humans changed by milenna of life in the darkness of the Undercroft.",
        image: "/lore/REVENANTS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/REVENANTS-thumb.png",
        faction: "dark",
      },
      {
        name: "HEXBOUND",
        text: "A mindless raving horde of sickly, accursed undead that desires only to spread its curse further.",
        image: "/lore/THE-HEXBOUND.jpg",
        imageThumbnail: "/images/how-to-play/heroes/THE-HEXBOUND-thumb.png",
        faction: "dark",
      },
      {
        name: "HOPLITES",
        text: "A race of amphibian scavengers who have built a society based on ancient fairytales they found.",
        image: "/lore/HOPLITES.jpg",
        imageThumbnail: "/images/how-to-play/heroes/HOPLITES-thumb.png",
        faction: "earth",
      },
      {
        name: "SAURIAN",
        text: "A primal race of reptilian humanoids native to the jungles of Sky, forming the last vestiges of an ancient civilization.",
        image: "/lore/SAURIAN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/SAURIAN-thumb.png",
        faction: "earth",
      },
      {
        name: "TREEFOLK",
        text: "A long-lived race of treelike beings native to the Etherwald. They serve as living relics of the past, repositories of knowledge, and ancient magics.",
        image: "/lore/TREEFOLK.jpg",
        imageThumbnail: "/images/how-to-play/heroes/TREEFOLK-thumb.png",
        faction: "earth",
      },
      {
        name: "SPOREKIN",
        text: "A timid, tightly knit, and peaceful race of mushroom people who possess powerful and desirable toxic spores.",
        image: "/lore/SPOREKIN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/SPOREKIN-thumb.png",
        faction: "earth",
      },
      {
        name: "DRYADS",
        text: "Daughters of the goddess Ethera, the Dryads protect the Etherwald and its vast natural resources from outsiders.",
        image: "/lore/DRYADS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/DRYADS-thumb.png",
        faction: "earth",
      },
      {
        name: "WOLFKIN",
        text: "Powerful and aggressive Beastfolk who live alongside the humans of the wildlands, working together for survival.",
        image: "/lore/WOLFKIN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/WOLFKIN-thumb.png",
        faction: "fire",
      },
      {
        name: "VULPINE",
        text: "A mischievous fox race of mages, swordsmen and tricksters.",
        image: "/lore/VULPINE.jpg",
        imageThumbnail: "/images/how-to-play/heroes/VULPINE-thumb.png",
        faction: "fire",
      },
      {
        name: "ONI",
        text: "Powerful and honorable humanoids native to the mountains of Sky, who possess demonic heritage.",
        image: "/lore/ONI.jpg",
        imageThumbnail: "/images/how-to-play/heroes/ONI-thumb.png",
        faction: "fire",
      },
      {
        name: "LEORAN",
        text: "A proud feline people with an affinity for lightning magic and a knack for adopting new allies.",
        image: "/lore/LEORAN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/LEORAN-thumb.png",
        faction: "light",
      },
      // {
      // 	name: "RASHIDANS",
      // 	text: "Humans native to the desert region of Rashida. Masters of Light Element Magic.",
      // 	image: "/lore/RASHIDANS.jpg",
      // 	imageThumbnail: "/images/how-to-play/heroes/RASHIDANS-thumb.png",
      // 	faction: "light"
      // },
      {
        name: "THE SURIT",
        text: "A theocratic, sun-worshipping society of meerkat people, who have lived in Sky’s deserts since time immemorial.",
        image: "/lore/THE-SURIT.jpg",
        imageThumbnail: "/images/how-to-play/heroes/THE-SURIT-thumb.png",
        faction: "light",
      },
      {
        name: "LAPIN",
        text: "A stubborn but good-natured race of rabbit people. Master engineers, miners, and craftspeople.",
        image: "/lore/LAPIN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/LAPIN-thumb.png",
        faction: "metal",
      },
      {
        name: "MELLIN",
        text: "A mischievous, free-spirited badger people. Experts with explosives and at breaking into the most secure places in Sky.",
        image: "/lore/BADGERKIN.jpg",
        imageThumbnail: "/images/how-to-play/heroes/BADGERKIN-thumb.png",
        faction: "metal",
      },
      {
        name: "THE ARMIS",
        text: "A society of humans governed by authoritarian rule. They believe their destiny is to bring order and stability to all of Sky.",
        image: "/lore/THE-ARMIS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/THE-ARMIS-thumb.png",
        faction: "metal",
      },
      {
        name: "ANIMATA",
        text: "Mischievous living objects given life by an overexposure to mind element magic.",
        image: "/lore/ANIMATA.jpg",
        imageThumbnail: "/images/how-to-play/heroes/ANIMATA-thumb.png",
        faction: "mind",
      },
      {
        name: "MASKED DREAMERS",
        text: "A bizarre race of beings native to the Dreamfield, dedicated to seeking out and accumulating knowledge.",
        image: "/lore/MASKED-DREAMERS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/MASKED-DREAMERS-thumb.png",
        faction: "mind",
      },
      {
        name: "DREAMBEASTS",
        text: "Creatures born of the dreams and nightmares of mortals. They stalk the Dreamfield in search of wayward minds.",
        image: "/lore/DREAMBEASTS.jpg",
        imageThumbnail: "/images/how-to-play/heroes/DREAMBEASTS-thumb.png",
        faction: "mind",
      },
    ],
  },
};

// icons found at /images/prism/<ICON>.png
export const PRISMS = [
  { title: "strength", icon: "STR", text: "Strength" },
  { title: "strength-wisdom", icon: "STW", text: "Strength & Wisdom" },
  { title: "strength-intellect", icon: "STI", text: "Strength & Intellect" },
  { title: "strength-heart", icon: "STH", text: "Strength & Heart" },
  { title: "strength-agility", icon: "STA", text: "Strength & Agility" },
  { title: "agility", icon: "AGY", text: "Agility" },
  { title: "agility-intellect", icon: "AGI", text: "Agility & Intellect" },
  { title: "agility-wisdom", icon: "AGW", text: "Agility & Wisdom" },
  { title: "heart", icon: "HRT", text: "Heart" },
  { title: "heart-intellect", icon: "HRI", text: "Heart & Intellect" },
  { title: "heart-agility", icon: "HRA", text: "Heart & Agility" },
  { title: "intellect", icon: "INT", text: "Intellect" },
  { title: "intellect-wisdom", icon: "INW", text: "Intellect & Wisdom" },
  { title: "wisdom", icon: "WIS", text: "Wisdom" },
  { title: "heart-wisdom", icon: "HRW", text: "Heart & Wisdom" },
];

// icons found at /images/elements/<ICON>.png
export const ELEMENTS = [
  { icon: "air", color: "#447C73" },
  { icon: "water", color: "#4870A9" },
  { icon: "dark", color: "#642C67" },
  { icon: "earth", color: "#21641B" },
  { icon: "fire", color: "#7D1111" },
  { icon: "light", color: "#DDBF2A" },
  { icon: "metal", color: "#4E4E4E" },
  { icon: "mind", color: "#56278E" },
];
